<template>
  <Disclosure v-slot="{ open }">
    <tr class="border-lighterGray border-b last:border-b-0 h-16">
      <td class="pl-8">
        {{ group.name }}
      </td>
      <td>
        {{ group.id }}
      </td>

      <td class="text-right whitespace-nowrap pr-11">
        <Button
          variant="secondary"
          class="px-10"
          @click="openExportDialog(group.id)"
          >{{ $t('pages.export') }}</Button
        >
        <DisclosureButton as="template">
          <Icon
            class="inline-block cursor-pointer transition-transform w-8 lg:w-6 ml-9 lg:ml-6"
            :class="{
              'transform-gpu rotate-180': open,
              'opacity-50 pointer-events-none': !group.assets?.length,
            }"
            name="chevron-down"
          />
        </DisclosureButton>
      </td>
    </tr>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform-gpu scale-95 opacity-0"
      enter-to-class="transform-gpu scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform-gpu scale-100 opacity-100"
      leave-to-class="transform-gpu scale-95 opacity-0"
    >
      <DisclosurePanel as="tr">
        <td colspan="4">
          <table class="table-auto w-full mt-2">
            <tbody>
              <template v-for="asset in group.assets" :key="asset.assetId">
                <tr
                  class="border-lighterGray border-b h-16"
                  @click="$emit('edit:object', asset.assetId)"
                >
                  <td class="pl-4 w-4/12">
                    <div class="flex gap-x-4 pl-16">
                      <checkbox
                        v-model="objToBeExported"
                        :value="asset.assetId"
                        :disabled="asset.state === null"
                      ></checkbox>
                      {{ asset.name }}
                    </div>
                  </td>
                  <td :class="`text-${statusColor(asset.state)} w-8/12`">
                    <!-- hidden element to trigger tailwind to generate these classes -->
                    <i class="text-oceanGreen bg-oceanGreen hidden" />
                    <div class="flex items-center">
                      <span
                        class="flex-shrink-0 rounded-full h-5 w-5 xl:h-6 xl:w-6"
                        :class="`bg-${statusColor(asset.state)}`"
                      />
                      <span class="font-semibold ml-3">{{
                        statusText(asset.state)
                      }}</span>
                    </div>
                  </td>
                </tr>
                <!-- spacer -->
                <tr class="h-2"></tr>
              </template>
            </tbody>
          </table>
        </td>
      </DisclosurePanel>
    </transition>
  </Disclosure>
  <ExportObjectsOrgDialog
    v-model="openExportObjectsOrg"
    v-bind="{ groupId, objToBeExported }"
  ></ExportObjectsOrgDialog>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import Icon from '@/components/Icon'
import Checkbox from '@/components/form/Checkbox.vue'
import Button from '@/components/form/button/Button.vue'
import { ExportObjectsOrgDialog } from '@/components/dialog'
import { EAssetState } from '@/enums'

export default {
  data() {
    return {
      objToBeExported: [],
      openExportObjectsOrg: false,
      groupId: null,
    }
  },
  components: {
    Icon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Checkbox,
    Button,
    ExportObjectsOrgDialog,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openExportDialog(id) {
      this.openExportObjectsOrg = true
      this.groupId = id
    },
    statusColor(status) {
      switch (status) {
        case EAssetState.Active:
        case EAssetState.Review:
        case EAssetState.Evaluation:
          return 'oceanGreen'
        case EAssetState.Closed:
          return 'primary'
        case EAssetState.Declined:
        case EAssetState.DefinitelyDeclined:
        case EAssetState.Expired:
          return 'error'
        case EAssetState.Verification:
          return 'yellow'
        default:
          // status is null, this means 'to be answered'
          return 'lightGray'
      }
    },
    statusText(status) {
      const prefix = 'assetManagement.objects-status'

      switch (status) {
        case EAssetState.Active:
          return this.$t(`${prefix}-active`)
        case EAssetState.Review:
          return this.$t(`${prefix}-review`)
        case EAssetState.Evaluation:
          return this.$t(`${prefix}-evaluation`)
        case EAssetState.Closed:
          return this.$t(`${prefix}-closed`)
        case EAssetState.Declined:
          return this.$t(`${prefix}-declined`)
        case EAssetState.Verification:
          return this.$t(`${prefix}-verification`)
        case EAssetState.DefinitelyDeclined:
          return this.$t(`${prefix}-definitelyDeclined`)
        case EAssetState.Expired:
          return this.$t(`${prefix}-expired`)
        default:
          // status is null, this means 'to be answered'
          return this.$t(`${prefix}-open`)
      }
    },
  },
}
</script>
